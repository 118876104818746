import React from "react";
import { render } from "react-dom";
import DemoApp from "./DemoApp";

import "./style.css";
export const BASE_URL = window.location.origin; //the BaseUrl where the content gets fetched, displayed,...
//export const BASE_URL = 'http://localhost:5135'

export let TooltipKey = ""; //name of the key, for the tooltip
export let TitleKey = ""; //name of the key, for the Title
document.addEventListener("DOMContentLoaded", async function () {

  processDetailsAndRender();



});

async function RenderTheCalendar() {
  const url = new URL(window.location.href);
  const params = url.searchParams;
  const paramValue = params.get("alias");

  try {
      const response = await fetch(BASE_URL + "/InitCalendar" + "?alias=" + paramValue);
      const data = await response.text();

      if (data == "" || data == null) {
          render(
              <>
                  <div id="CustomHeader">
                      <h1 id="CustomHeaderText">{"Kein gültiger Alias, oder kein Alias vorhanden!"}</h1>
                  </div>
                  {alert("kein gültiger Alias")}
              </>,
              document.body.appendChild(document.createElement("div"))
          );
      } else {
          render(
              <>
                  <div id="CustomHeader">
                      <h1 id="CustomHeaderText">{data}</h1>
                  </div>
                  <DemoApp />
              </>,
              document.body.appendChild(document.createElement("div"))
          );
      }
  } catch (error) {
    //  console.error(error);
      render(
          <>
              <div id="CustomHeader">
                  <h1 id="CustomHeaderText">Server nicht erreichbar</h1>
              </div>
          </>,
          document.body.appendChild(document.createElement("div"))
      );
  }
}


async function processDetailsAndRender() {
  await ExportTheDetails();
  await RenderTheCalendar();
}



async function ExportTheDetails() {
  document.title = "Tonhalle";

  const url = new URL(window.location.href);
  const params = url.searchParams;
  const paramValue = params.get("alias");

  try {
      const tooltipResponse = await fetch(BASE_URL + "/getTooltipKey" + "?alias=" + paramValue);
      if (!tooltipResponse.ok) {
          throw new Error("Network response was not ok");
      }
      TooltipKey = await tooltipResponse.text();

      const titleResponse = await fetch(BASE_URL + "/getTitleKey" + "?alias=" + paramValue);
      if (!titleResponse.ok) {
          throw new Error("Network response was not ok");
      }
      TitleKey = await titleResponse.text();
      //Console.log(TitleKey + "-------------)");
  } catch (error) {
    //  console.error("There was a problem with the fetch operation:", error);
  }
}
