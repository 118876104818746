//#region imports

import React, { useState } from "react";
import ReactDOM from "react-dom";
import deLocale from "@fullcalendar/core/locales/de";
import moment from "moment";

import {
  EventApi,
  DateSelectArg,
  EventClickArg,
  EventContentArg,
  formatDate,
  EventInput,
  Calendar,
  DatesSetArg,
  EventHoveringArg,
} from "@fullcalendar/core";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { INITIAL_EVENTS, createEventId } from "./event-utils";
import { render } from "@fullcalendar/core/preact";
import {
  eventTupleToStore,
  preventContextMenu,
} from "@fullcalendar/core/internal";
import { cA, dE, S } from "@fullcalendar/core/internal-common";
import Tooltip from "@mui/material/Tooltip";

import Toggle from "react-toggle";
import "react-toggle/style.css";
import { useMediaQuery } from "react-responsive";

import { json } from "react-router-dom";

import bootstrap5Plugin from "@fullcalendar/bootstrap5";

import "bootstrap/dist/css/bootstrap.css";
//#region  Imports from the index.tsx

import { BASE_URL } from "./index";
import { TooltipKey } from "./index";
import { TitleKey } from "./index";


//#endregion

import { dark, light } from "@mui/material/styles/createPalette";
import { colors } from "@mui/material";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";


library.add(fas);
//#endregion
//#region globalVariables
let imageForPopup: Blob;
let calendarApi: FullCalendar;
let hasDataInLocalStorage = true;
let ApiChanged = true;
let setDarkmodeButtonfont = true;
//#endregion
/**
 * Takes Time in Format TT.MM.JJJJ.HH.MM.SS
 * @param dateString
 * @returns  the date in Iso 8601 form
 */
function convertToISO(dateString: any) {
  let parts = dateString.split(" ");
  let dateParts = parts[0].split(".");
  let timeParts = parts[1].split(":");

  let date = new Date(
    dateParts[2],
    dateParts[1] - 1,
    dateParts[0],
    timeParts[0],
    timeParts[1],
    timeParts[2]
  );
  return date;
}

/**
 * A Custom Fuction to store things in the Local storage
 * @param key the keyname for the storedItem
 * @param toBeStored The Item that should be Stored
 */
function storeInLocalStorage(key: string, toBeStored: any) {
  localStorage.setItem(key, toBeStored);
}

/**
 * A Custom fuction to get items from Localstorage as a String
 * @param key The key of the Item that you want
 * @returns The Corresponding String to the Key
 */
function GetFromLocalStorage(key: string) {
  return localStorage.getItem(key) + "";
}

interface DemoAppState {
  lastTimeLoadedDates: number;
  timeAfterAutoRefreshinMinutes: number;
  hasLoaded: boolean;
  httpSource: string;
}

/**
 * A Methode that converts string to a number Value so that not everytime a long string has to be saved in the Local storage
 * @param string 
 * @returns 
 */
function stringToHash(string: string) {
  let hash = 0;

  if (string.length == 0) return hash;

  for (var i = 0; i < string.length; i++) {
    var char = string.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }

  return hash;
}

/**
 * Checks if the Corresponding Api changed since last Time(last time is saved as hash in the Local storage)
 * sets then the boolean apiChanged to the corrosponding value
 */
function selectRightCalendarDates() {
  const url = new URL(window.location.href);
  const params = url.searchParams;
  const paramValue = params.get("alias");

  if (
    localStorage.getItem("lastApikeyHashValue") ==
    stringToHash(paramValue + "").toString()
  ) {
    ApiChanged = false;
  } else {
    ApiChanged = true;
    localStorage.setItem(
      "lastApikeyHashValue",
      stringToHash(paramValue + "").toString()
    );
  }
}

/**
 * The ReactExport
 */
export default class DemoApp extends React.Component<{}, DemoAppState> {
  [x: string]: any;

  state: DemoAppState = {
    timeAfterAutoRefreshinMinutes: 5,//the time after the the calandar refreshes its event
    hasLoaded: false,
    lastTimeLoadedDates: 0,
    httpSource: BASE_URL,
  };
  /**
   * Renders the calendar
   * @returns the Calandar(renderd and finished as Html)
   */
  render() {
    //InitCalandar(this.state.httpSource)

    loadColor();

    //#region check and Set StartDate and Viewtype and sets upp the Autorefresh of the Events
    let startDate = GetFromLocalStorage("StartDate");//gets the intitial stardate 
    let viewType = JSON.parse(GetFromLocalStorage("viewType"));//gets the intitial viewtype 

    if (startDate == "null") {
      startDate = new Date().toDateString();
    }
    if (viewType == null) {
      viewType = "dayGridMonth";
    }
    this.state.lastTimeLoadedDates = parseInt(
      GetFromLocalStorage("timeCalandarSaved")
    );
    setInterval(
      this.initializeDates,
      this.state.timeAfterAutoRefreshinMinutes * 29000
    );

    if (viewType == "") {
      viewType = "dayGridMonth";
    }

    if (startDate == "") {
      startDate = new Date().toString();
    } else {
      if (viewType == "dayGridMonth") {
        let startDateParsed = new Date(startDate);
        let startDateAsNumber = startDateParsed.getTime();
        startDateAsNumber = startDateAsNumber + 1296000000;
        startDate = new Date(startDateAsNumber).toString();
      }
    }

    let startDateParsed = new Date(startDate);
    let start = startDateParsed.toISOString();
    //#endregion

    return (//sets up the settings of Calandar
      <div id="OuterDiv" className="demo-app" style={{ height: "90vh" }}>
        <div className="demo-app-main" id="CalendarDiv">
          <FullCalendar
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              bootstrap5Plugin,
            ]}
            themeSystem="bootsstrap5"

            //A Custom button for the Changing to Darkmode
            customButtons={{
              ChangeDarkmodeButton: {
                text: "",
                // icon: 'blah glyphicon glyphicon-eye-open forceIcons', //glyphicon glyphicon-sunglasses
                click: changeStyle,
              },
            }}

            headerToolbar={{//you can set the Butons you want to display here
              left: "prev,next today ChangeDarkmodeButton",
              center: "title",
              right: "dayGridMonth,dayGridWeek,dayGridDay",
            }}
            locale={selectedLanguage()}//sets the language of the calandar
            initialView={viewType}
            allDaySlot={false}//if alldayevents should be displayed
            initialDate={start}
            editable={false}//if the User can edit things
            selectable={false}
            selectMirror={true}
            dayMaxEvents={true}
            weekends={true}//If Weekends should be Displayed(sunday,Saturday )
            eventTimeFormat={{//The timeformat
              hour: "2-digit",
              minute: "2-digit",
              meridiem: false,//if am/pm should be Displayed and how
              hour12: false,//if it should be in 12 or 24 our time(fase:13:45;;;;true:1:45)
            }}
            expandRows={false}
            eventDisplay={"block"}
            datesSet={this.initializeDates} // used for initial injeciton of dates
            eventClick={this.handleEventClick} // when Event is clicked, cll for deleting
            eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed*/ // called after datesSet
            dayMaxEventRows={false}
            views={{
              timeGrid: {
                dayMaxEventRows: 8, // adjust to 6 only for timeGridWeek/timeGridDay
                dayMaxEvents: 8,
              },
            }}
            eventMaxStack={15}
            contentHeight={"auto"}
          />
        </div>
      </div>
    );
  }



  initializeDates = (datesSetArg: DatesSetArg) => {//gets Called Everytime something changes in the Calandar(A Event, A Title, The View, ....)

    if (this.calendarApi == null) {
      this.calendarApi = datesSetArg.view.calendar;
    }
    ApiChanged = true;
    selectRightCalendarDates();
    const url = new URL(window.location.href);
    const params = url.searchParams;
    const paramValue = params.get("alias");

    if (!document.getElementById("IconHasLoaded")) {//sets the Darkmode Icon
      if (!document.getElementById("IconHasLoaded")) {
        fetch(BASE_URL + "/Get_DarkModeIcon" + "?alias=" + paramValue)
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.text(); // Fetch the SVG content as text
          })
          .then((svgText) => {
            const svgElement = new DOMParser().parseFromString(
              svgText,
              "image/svg+xml"
            ).documentElement;
            svgElement.setAttribute("class", "forceIcons");
            svgElement.setAttribute("width", "20");
            svgElement.setAttribute("height", "20");
            svgElement.id = "IconHasLoaded";
            document
              .getElementsByClassName("fc-ChangeDarkmodeButton-button")[0]
              .appendChild(svgElement);
          })
          .catch((error) => {
            console.error(error)
          });
      }
    }

    document.title =
      document.getElementById("CustomHeaderText")?.textContent?.toString() + "";

    //Console.log("init Dates aufgerufen");

    if (datesSetArg != null) {//stores the View that is Selected
      storeInLocalStorage("viewType", JSON.stringify(datesSetArg.view.type));
      storeInLocalStorage("StartDate", datesSetArg.view.activeStart);
    }


    //checks if the Calandar has Data in the Locale Storage
    if (
      GetFromLocalStorage("calandarData") == "null" ||
      Number.isNaN(this.state.lastTimeLoadedDates)
    ) {
      hasDataInLocalStorage = false;
      //Console.log("sethasDatainLocalStorage false");
    }

    //#region FetchSettings
    //fetches The Brandcolor from the Json
    fetch(BASE_URL + "/getBrandColor" + "?alias=" + paramValue)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.text();
      })
      .then((data) => {
        var root = document.querySelector(":root") as HTMLElement;
        if (root) {
          root.style.setProperty("--brand_color", data);
        }
      })
      .catch((error) => {
        //  console.error("There was a problem with the fetch operation:", error);
      });
    //fetches The Picture and Saves it from the Json
    fetch(BASE_URL + "/Get_Picture" + "?alias=" + paramValue)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        imageForPopup = blob;
      })
      .catch((error) => {
        //  console.error("There was a problem with the fetch operation:", error);
      });
    //fetches The EventColor
    fetch(BASE_URL + "/Get_event_color" + "?alias=" + paramValue)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.text();
      })
      .then((data) => {
        var root = document.querySelector(":root") as HTMLElement;
        if (root) {
          root.style.setProperty("--event_color", data);
        }
      })
      .catch((error) => {
        // console.error("There was a problem with the fetch operation:", error);
      });
    //#endregion
    //#region AddEvents 
    //checks if The Calandar should be Reloaded, Initialized,.. from the Locale Storage or From the Web
    if (
      this.state.lastTimeLoadedDates +
      this.state.timeAfterAutoRefreshinMinutes * 60000 <
      Date.now() ||
      hasDataInLocalStorage == false ||
      ApiChanged
    ) {
      //Console.log("Daten Aktualisiert von Web");
    } else if (
      !this.state.hasLoaded &&
      hasDataInLocalStorage == true &&
      ApiChanged == false
    ) {
      //Console.log("Laded das erste mal lokal(lokal)");
      this.state.hasLoaded = true;
      this.calendarApi.removeAllEvents();

      //Console.log(TooltipKey);
      //Console.log(TitleKey);
      JSON.parse(GetFromLocalStorage("calandarData")).Entities.forEach((event: any) => {//loads and sets the Events from the Locale Storage
        //Console.log(event[TitleKey] + "-----------" + TitleKey)
        let tempEvent = {
          id: event.ID,
          title: event[TitleKey],
          start: convertToISO(event.date_start),
          end: convertToISO(event.date_end),
          extendedProps: {
            gDisp_web_calendar: event[TooltipKey],
          },
        };

        // //Console.log(tempEvent)
        this.calendarApi.addEvent(tempEvent);
      });

      return;
    } else {
      //Console.log("elselselse");
      return;
    }

    //ApiChanged = false;
    this.state.hasLoaded = true;
    //Console.log(this.state.httpSource + "/calendarData?alias=" + paramValue +"!importatnt");
    //loads and sets the Events from the Web
    fetch(this.state.httpSource + "/calendarData?alias=" + paramValue)
      .then((response) => response.json())
      .then((jsonData) => {
        this.calendarApi.removeAllEvents();
        //Console.log("saved Over old Calendar data");
        storeInLocalStorage("calandarData", JSON.stringify(jsonData));
        let currentTimeinMilliseconds = Date.now();
        storeInLocalStorage(
          "timeCalandarSaved",
          currentTimeinMilliseconds.toString()
        );
        this.state.lastTimeLoadedDates = currentTimeinMilliseconds;
        jsonData.Entities.forEach((event: any) => {
          let tempEvent = {
            id: event.ID,
            title: event[TitleKey],
            start: convertToISO(event.date_start),
            end: convertToISO(event.date_end),
            extendedProps: {
              gDisp_web_calendar: event[TooltipKey],
            },
          };

          this.calendarApi.addEvent(tempEvent);
        });
      })
      .catch((error: any) => {
        //    console.error(error);
      });
    //#endregion
  };


  /**
   * Creates and Sets a new Element over the Calandar and Displays it when Stgh. is Clicked
   * @param clickInfo The Clickinfo of the Event Clicked
   */
  handleEventClick = (clickInfo: EventClickArg) => {
    const outerDiv = document.createElement("div");
    outerDiv.id = "EventClickOuterDivStyle";

    const innerDiv = document.createElement("div");
    innerDiv.id = "EventClickInnerDivStyle";

    const buttondiv = document.createElement("div");
    buttondiv.id = "EventClickButtonDivStyle";

    const HeaderTextPopup = document.createElement("h1");

    //HeaderTextPopup.textContent = document.getElementById("CustomHeaderText")?.textContent + '-' + clickInfo.event.start?.getDay() + "ter    " + document.getElementById("fc-dom-1")?.textContent

    const eventStart = clickInfo.event.start;
    if (eventStart) {
      const eventDate = new Date(eventStart);
      const month = eventDate.toLocaleString("default", { month: "long" });
      const day = eventDate.getDate();
      const year = eventDate.getFullYear();
      
      HeaderTextPopup.textContent =
        document.getElementById("CustomHeaderText")?.textContent +
        " - " +
        day +
        ". " +
        month +
        " " +
        year;
    } else {
      //  console.error("Event start date is null");
    }

    HeaderTextPopup.id = "EventClickHeaderTextPopupStyle";
    const closeButton = document.createElement("button");
    closeButton.id = "EventlClickInnerButtonStyle";
    closeButton.addEventListener("click", () => {
      var root = document.querySelector(":root") as HTMLElement;
      if (root) {
        root.style.setProperty("--blurFactorBackgorund", "0px");
      }
      document.body.removeChild(outerDiv);
    });
    closeButton.className = "glyphicon glyphicon-remove CloseSymol";

    const textdiv = document.createElement("div");
    textdiv.id = "EventClickTextDivStyle";

    let description = document.createElement("pre");
    description.textContent =
      "" + clickInfo.event.extendedProps.gDisp_web_calendar;
    description.id = "EventClickTextTextStyle";
    //Console.log(clickInfo.event.extendedProps.gDisp_web_calendar as string);
    let startdate = document.createElement("h1");

    let minutesStart = clickInfo.event.start?.getMinutes().toString();
    if (clickInfo.event.start?.getMinutes().toString().length == 1) {
      minutesStart += "0";
    }
    let minutesEnd = clickInfo.event.start?.getMinutes().toString();
    if (clickInfo.event.end?.getMinutes().toString().length == 1) {
      minutesEnd += "0";
    }

    const TimeSymbol = document.createElement("span");
    TimeSymbol.className = "glyphicon glyphicon-time TimeSymbol";
    TimeSymbol.setAttribute("role", "img");

    const DescriptionSymbol = document.createElement("span");
    DescriptionSymbol.className =
      "	glyphicon glyphicon-list-alt DescriptionSymbol";
    DescriptionSymbol.setAttribute("role", "img");
    moment.locale(detectLocale());
    startdate.textContent =
      "" +
    moment(clickInfo.event.start).format('LT') +
      " - " +
    moment(clickInfo.event.end).format('LT');
    startdate.id = "EventClickTextTextStyle";

    const Picturediv = document.createElement("div");
    Picturediv.id = "PictureDiv";
    const OverPictureDiv = document.createElement("div");
    OverPictureDiv.id = "OverPictureDiv";

    const HeaderDivDiv = document.createElement("div");
    HeaderDivDiv.id = "HeaderDivDiv";
    const PictureDivDiv = document.createElement("div");
    PictureDivDiv.id = "PictureDivDiv";

    try {
      Picturediv.style.backgroundImage = `url(${URL.createObjectURL(
        imageForPopup
      )})`;
    } catch { }

    textdiv.appendChild(startdate);
    textdiv.appendChild(description);

    buttondiv.appendChild(HeaderTextPopup);
    buttondiv.appendChild(closeButton);

    HeaderDivDiv.appendChild(buttondiv);
    HeaderDivDiv.appendChild(textdiv);
    Picturediv.appendChild(OverPictureDiv);

    HeaderDivDiv.appendChild(Picturediv);

    innerDiv.appendChild(HeaderDivDiv);

    var root = document.querySelector(":root") as HTMLElement;
    if (root) {
      ////Console.log("sdsdsdsd");
      root.style.setProperty("--blurFactorBackgorund", "2.5px");
    }

    outerDiv.appendChild(innerDiv);
    document.body.appendChild(outerDiv);

    startdate.insertBefore(TimeSymbol, startdate.firstChild);
    description.insertBefore(DescriptionSymbol, description.firstChild);
  };
}

function detectLocale() {
  const userLocale = navigator.languages ? navigator.languages[0] : navigator.language;
  return userLocale.split('-')[0]; // Get the language code (e.g., 'en', 'fr')
}

/**loads the prev. selected Color, when not selected Before it takes the System color */
function loadColor(this: any) {
  let prevColor;
  if (localStorage.getItem("prevColor") != null) {
    if (localStorage.getItem("prevColor") == "dark") {
      prevColor = "dark";
    } else {
      prevColor = "light";
    }
  } else if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
    prevColor = "dark";
  } else {
    prevColor = "light";
  }

  document.documentElement.setAttribute("data-theme", prevColor);
}
/**
 * The function changes from Darkmode to Light mode and back
 * @param mouseEvent 
 * @param darkModeButton 
 */
const changeStyle = (mouseEvent: MouseEvent, darkModeButton: HTMLElement) => {
  if (document.documentElement.getAttribute("data-theme") === "dark") {
    document.documentElement.setAttribute("data-theme", "light");
    localStorage.setItem("prevColor", "light");
  } else {
    document.documentElement.setAttribute("data-theme", "dark");
    localStorage.setItem("prevColor", "dark");
  }
};

/**get The User language */
function selectedLanguage() {
  let temp;
  //Console.log(navigator.language);
  if (navigator.language.includes('de')) {
    temp = deLocale;
  } else {
    temp = "en";
  }
  return temp;
}
